import React from "react"
import style from "./isaac.module.scss"


const Isaac = ({ content }) => {


    console.log("content", content)

    return(
        <div className={style.isaac}>
            <video autoPlay loop muted>
                
            {
                content.isaacFullVideo.map((video, i) => (
                    <source key={i} src={video.filename} type={`video/${video.filename.split('.').pop()}`}/>
                ))
            }
            {/* <Video */}
            </video>
        </div>
    )
}

export default Isaac
